import React from "react";
import { SEO, PageLayout, LocationsSorted } from "@bluefin/components";
import { Grid } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import { getComponentFiles } from "../utils/utils";

export default class LocationsPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          description={fishermanBusinessWebsitePage.description}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
        />
        <PageLayout className={"locations-page"}>
          <Grid
            stackable={true}
            className={"component-section-container"}
            verticalAlign={"middle"}
            textAlign={"center"}
          >
            <Grid.Column width={10} textAlign={"center"}>
              <LocationsSorted locations={fishermanBusiness.locations} />
            </Grid.Column>
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
      locations {
        name
        timezone
        phoneNumber
        email
        hours {
          open
          close
          day
          label
        }
        street
        city
        state
        zipCode
        country
        latitude
        longitude
      }
    }
    fishermanBusinessWebsitePage(pageType: { eq: "Locations" }) {
      title
      description
      components {
        fastId
        componentIdentifier
        order
        files {
          file
        }
      }
    }
  }
`;
